var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "accordion-study" },
    _vm._l(_vm.accordionData, function (item, idx) {
      return _c(
        "div",
        {
          key: "accordion" + idx,
          staticClass: "accordion-study__item",
          class: { _active: _vm.openAccordionId === idx },
          on: {
            click: function ($event) {
              return _vm.toggleAccordion(idx)
            },
          },
        },
        [
          _c("div", { staticClass: "d-flex w-100" }, [
            _c("div", { staticClass: "accordion-study__inner" }, [
              _c("span", { staticClass: "accordion-study__inner__h" }, [
                _vm._v(" " + _vm._s(item.theme) + " "),
              ]),
              _c("p", [_vm._v(_vm._s(item.name))]),
            ]),
            _c(
              "span",
              {
                staticClass: "accordion-study__btn",
                class: { _rotate: _vm.openAccordionId === idx },
              },
              [_vm._v(" ❭ ")]
            ),
          ]),
          _c(
            "transition",
            {
              attrs: { name: "acc" },
              on: {
                "before-enter": function ($event) {
                  $event.style.height = "0px"
                },
                enter: function ($event) {
                  $event.style.height = $event.scrollHeight + "px"
                },
                "before-leave": function ($event) {
                  $event.style.height = $event.scrollHeight + "px"
                },
                leave: function ($event) {
                  $event.style.height = "0px"
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.openAccordionId === idx,
                      expression: "openAccordionId === idx",
                    },
                  ],
                  staticClass: "accordion-study__answer",
                },
                [
                  _c("div", {
                    staticClass: "accordion-study__answer__desc",
                    domProps: { innerHTML: _vm._s(item.shortDescription) },
                  }),
                  _vm._t("default"),
                ],
                2
              ),
            ]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }