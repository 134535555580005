<template>
  <div class="accordion-study">
    <div
      :class="{'_active': openAccordionId === idx}"
      class='accordion-study__item'
      v-for="(item, idx) in accordionData"
      :key="'accordion' + idx"
      @click="toggleAccordion(idx)"
    >
      <div class="d-flex w-100">
        <div class="accordion-study__inner">
          <span class="accordion-study__inner__h">
            {{ item.theme }}
          </span>
          <p>{{ item.name }}</p>
        </div>
        <span
          :class="{'_rotate': openAccordionId === idx}"
          class="accordion-study__btn">
          ❭
        </span>
      </div>
      <transition
        name="acc"
        @before-enter="$event.style.height = '0px'"
        @enter="$event.style.height = $event.scrollHeight + 'px'"
        @before-leave="$event.style.height = $event.scrollHeight + 'px'"
        @leave="$event.style.height = '0px'"
      >
        <div class="accordion-study__answer" v-show="openAccordionId === idx">
          <div
            v-html="item.shortDescription"
            class="accordion-study__answer__desc"
          >
          </div>
          <slot></slot>
        </div>
      </transition>

    </div>
  </div>
</template>
<script>
export default {
  name: 'LessonAccordionComponent',
  props: {
    accordionData: {}
  },
  data() {
    return {
      openAccordionId: null,
    }
  },
  methods: {
    toggleAccordion(idx) {
      this.openAccordionId !== idx
        ? (this.openAccordionId = idx)
        : (this.openAccordionId = null)
    },
  }
}
</script>

<style lang="scss" scoped>
.accordion-study {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
  margin-top: 16px;
  margin-bottom: 16px;

  p {
    margin: 0;
  }

  &__block {
    display: inline-flex;
    max-height: 300px;
    overflow-y: auto;
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    width: 100%;
    background: var(--second-card-bg);
    min-height: 72px;
    padding: 8px 20px 8px 16px;
    border-radius: 4px;

    @include media-breakpoint-up(md) {
      padding: 22px 32px;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: baseline;
      gap: 30px;
    }

    &__h {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 4px;
      width: 240px;

      @include media-breakpoint-up(md) {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }

      @include media-breakpoint-up(md) {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        width: 300px;
      }
    }
  }

  &__answer {
    margin-top: 12px;
    transition: 0.6s;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin-top: 24px;
    }

    &__desc {
      /deep/ {
        audio,
        iframe,
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  &__btn {
    cursor: pointer;
    transform: rotate(90deg);
    margin-left: auto;
    transition: 0.5s;
    height: 20px;
  }

  ._rotate {
    transform: rotate(-90deg);
  }

  ._active {
    background: var(--tab-active-bg);
  }
}

.acc-enter-active,
.acc-leave-active {
  margin-top: 12px;

  @include media-breakpoint-up(md) {
    margin-top: 24px;
  }
}

.acc-enter,
.acc-leave-to {
  margin-top: 0;
}
</style>
